import jsonlint from 'jsonlint-mod';

const jsonValidator = (str) => {
  try {
    return jsonlint.parse(str)
  } catch {
    return false;
  }
};

const matchSiteUrl = (v) => RegExp(/(http|https):\/\/[\w-]+(\.[\w-]+)+([\w.,@?^=%&amp;:/~+#-]*[\w@?^=%&amp;/~+#-])?/).test(v);

const matchFolderName = (v) => RegExp(/^$|^(?:(?:{{.*}.*}?)|(?:[^<>:?*"\\|]))+[^.<>:?*"\\|]$/g).test(v);

const matchEmail = (v) => RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/).test(v);

const matchEmailOrTemplate = (v) => matchEmail(v) || (v.startsWith('{{') && v.endsWith('}}'));

const matchEmailOrTemplateOrListOfEmails = (v) => {
  let success = true;

  if (!matchEmailOrTemplate(v)) {
    v.split(',').map((x) => x.split(';')
      /* eslint-disable-next-line */
      .map((z) => {
        let email = '';
        const arr = v.trim().split('');
        /* eslint-disable-next-line */
        arr.slice(arr.indexOf('<') + 1, arr.indexOf('>')).map((c) => email = `${email}${c}`);
        if (!RegExp(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/).test(email)) {
          success = false;
        }
      }));
  }

  return success;
};

const validateNameBasedOnEmail = (v, vm) => {
  if (vm && vm.email && (vm.email.includes(',') || vm.email.includes(';'))) {
    return true;
  }

  return v && !!v;
};

function debounceAsyncValidator(validator, delaySeconds) {
  let currentTimer = null;
  let currentPromiseReject = null;

  function debounce() {
    return new Promise((resolve, reject) => {
      currentTimer = setTimeout(() => {
        currentTimer = null;
        currentPromiseReject = null;
        resolve();
      }, delaySeconds * 1000);

      currentPromiseReject = reject;
    });
  }

  return function (value) {
    if (currentTimer) {
      currentPromiseReject(new Error('replaced'));
      clearTimeout(currentTimer);
      currentTimer = null;
    }

    return validator.call(this, value, debounce);
  };
}

export {
  required,
  email,
  numeric,
  url,
} from 'vuelidate/lib/validators/index.js';

export {
  jsonValidator,
  matchSiteUrl,
  matchFolderName,
  matchEmail,
  matchEmailOrTemplate,
  matchEmailOrTemplateOrListOfEmails,
  validateNameBasedOnEmail,
  debounceAsyncValidator,
};
